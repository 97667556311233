import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector, Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {PostAdGetterService} from '@thebell/common/services/utils/post-ad-getter';
import {CollapsePostComponent} from '@thebell/common/ui/widgets';
import {PostMeta, PublishedPost} from '@thebell/data-transfer-objects';
import {PostApiService} from '@thebell/frontend/api';
import {Subject, Subscription} from 'rxjs';
import {isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {StatusCodeError, StatusCodes} from '@thebell/erorrs';
import {SEOService} from '@thebell/common/services/others/seo';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {IEl, PostOnScreenResolverService} from '@thebell//frontend/element-on-screen-lib';
import {distinctUntilChanged} from 'rxjs/operators';
import {AccountLoginService} from '../account-login/account-login.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthClient} from '@thebell/common/services/api/auth/common-jwt';

@Component({
  selector: 'app-post-page',
  templateUrl: './post-page.component.html',
  styleUrls: ['./post-page.component.scss'],
  providers: [AccountLoginService, Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class PostPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() postSlug: string;
  @ViewChild('firstPost', {read: ElementRef, static: false}) firstPostRef: ElementRef;
  public postInjector: Injector;
  collapsePostComponent = CollapsePostComponent;
  post: PublishedPost;
  intersectionObserver: IntersectionObserver;
  isVisibleInView = new Subject<boolean>();
  style: {[key: string]: any} = {};
  detectedScreenSub: Subscription;
  next = false;
  firstPostLoading = true;
  loading = false;
  timer;
  mainPostLoaded = false;
  readonly environment: Environment;
  innerHeight;
  appUrl: string;
  totalPaywalls: number = 0;
  seenPaywalls: number = 0;
  isUserAuthorized: boolean = false;
  private startTime: number;
  private readingInterval: any;

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private postAdGetter: PostAdGetterService,
    private api: PostApiService,
    private titleService: Title,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private router: Router,
    private postService: PostApiService,
    private seo: SEOService,
    private location: Location,
    private envService: EnvironmentService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private postOnScreenResolverService: PostOnScreenResolverService,
    private readonly authClient: AuthClient
  ) {
    this.environment = this.envService.getEnvironment();
    this.checkAuthorization();
  }

  checkAuthorization() {
    if (this.authClient.isAuthSubscriber) {
      this.isUserAuthorized = true;
    }
  }

  async ngOnInit() {
    this.appUrl = this.environment.appUrl;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.route.params.subscribe(async (params) => {
      if (params.slug && this.post && params.slug !== this.post.slug) {
        this.unsubscribeObservable();
        this.post = null;
      }
      this.postAdGetter.resetAdRotate();
      try {
        this.firstPostLoading = true;
        this.post = await this.api.publishedPost(params.slug).toPromise();
        this.titleService.setTitle(this.post.title);
        this.postOnScreenResolverService.newItemEmitter.pipe(distinctUntilChanged()).subscribe((item) => {
          if (item && item.data) {
            this.setMeta(item);
          }
        });
        this.createObservable();
        // При открытии статьи начинаем отсчет времени чтения
        //this.startReadingTimer();
      } catch (err) {
        if (err instanceof StatusCodeError && err.code === StatusCodes.NOT_FOUND) {
          this.router.navigate(['/404'], {skipLocationChange: true});
        }
      }
      this.firstPostLoading = false;
    });
    if (isPlatformBrowser(this.platformId)) {
      this.timer = setTimeout(() => {
        this.next = true;
        this.loading = true;
      }, 15000);
    }
  }

  startReadingTimer() {
    this.startTime = Date.now();
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.post.content, 'text/html');
    const readingTimeSeconds = Math.ceil(doc.body.textContent.length / 1200 * 60);
    this.readingInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTimeSeconds = Math.floor((currentTime - this.startTime) / 1000);
      if (elapsedTimeSeconds >= readingTimeSeconds) {
        // Отправляем событие в GTM
        dataLayer.push({
          'event': 'article_read', 'category': 'post',
        });
        clearInterval(this.readingInterval);
      }
    }, 1000);
  }

  setSuccessLink() {
    ga('send', 'event', 'invest', 'snippet_subscribe', this.router.url.substring(1));
    dataLayer.push({
      'event': 'invest_paywall_snippet_subscribe', 'category': 'invest',
    });
    const expires = new Date(new Date().getTime() + 30 * 60000);
    this.cookieService.set('success-invest-paid-link', this.router.url.substring(1), expires);
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    this.mainPostLoaded = true;
    //if (!this.mainPostLoaded) return;
    const element = document.documentElement;
    const proc75 = (element.clientHeight / 4) * 3;
    const currentScroll = Math.floor(element.scrollHeight - element.scrollTop);
    if (currentScroll >= proc75 && !this.next) {
      this.next = true;
      this.loading = true;
      clearTimeout(this.timer);
    }

    const paywallSelectors = ['div[data-paywall="true"]', 'div[data-mailings-paywall="true"]'];
    const bannerBlocks = document.querySelectorAll(paywallSelectors.join(', '));
    this.totalPaywalls = bannerBlocks.length;

    bannerBlocks.forEach((bannerBlock) => {
      const boundingClientRect = bannerBlock.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      if (boundingClientRect.top <= viewportHeight && boundingClientRect.bottom >= 0 && this.seenPaywalls != this.totalPaywalls) {
        this.seenPaywalls += 1;
      }
    });
  }

  async setMeta(el: IEl) {
    const {slug, title, categorySlug} = el.data;
    //console.log('post-page el: ', el);
    let prefix_url = this.appUrl[this.appUrl.length - 1] === '/' ? this.appUrl : `${this.appUrl}/`;
    if (prefix_url === '/') {
      prefix_url = window.location.origin + '/';
    }

    const putMeta = (meta: PostMeta, isInvest: boolean) => {
      const seoTags = [
        {name: 'description', content: meta.description},
        {property: 'og:locale', content: 'ru_RU'},
        {property: 'og:type', content: 'article'},
        {property: 'og:title', content: meta.title},
        {property: 'og:description', content: meta.description},
        {property: 'og:url', content: `${prefix_url}${el.data.slug}`},
        {property: 'og:site_name', content: 'The Bell'},
        {property: 'article:publisher', content: 'https://www.facebook.com//'},
        {property: 'article:section', content: 'Новости'},
        {property: 'article:published_time', content: el.data.published_at?.toString()},
        {property: 'article:modified_time', content: el.data.updated_at?.toString()},
        {property: 'og:image', content: meta.image},
        {property: 'og:image:secure_url', content: meta.description},
        {property: 'og:image:width', content: '1200'},
        {property: 'og:image:height', content: '630'},
        {property: 'twitter:card', content: 'summary_large_image'},
        {property: 'twitter:description', content: meta.description},
        {property: 'twitter:title', content: meta.title},
        {property: 'twitter:site', content: '@thebell_io'},
        {property: 'twitter:image', content: meta.image},
        {property: 'twitter:creator', content: '@thebell_io'},
      ];
      if (isInvest) {
        seoTags.push({name: 'robots', content: 'noindex, nofollow'});
      }
      this.seo.push(seoTags);
    }
    const meta = await this.postService.meta(el.id, true).toPromise();
    const url = this.router.url.replace(/^\//, '').split('?')[0];
    const meta_url = prefix_url + el.data.slug;
    //console.log('post-page prefix_url: ', prefix_url);
    //console.log('post-page meta_url: ', meta_url);
    this.seo.addCanonical(meta_url);
    putMeta(meta, el.data.categorySlug === 'bell-investitsii');
    this.seo.addAmpLink(meta_url, el.data.categorySlug);
    // Обрезал параметры из URL.
    if (this.mainPostLoaded) {
      if (!this.location.isCurrentPathEqualTo('/' + slug)) {
        this.location.replaceState(slug, '', {
          navigationId: (this.location.getState() as any)?.navigationId + 1,
        });
      }
    }

    // Если при скролле и обновлении URL нам нужно сохранять параметры в URL
    // if (!this.location.isCurrentPathEqualTo('/' + slug)) {
    //   const currentParams = this.route.snapshot.queryParams;
    //   const params = Object.keys(currentParams).map(key => `${key}=${currentParams[key]}`).join('&')
    //   this.location.replaceState(slug, params, {
    //     queryParams: currentParams, // Передача текущих параметров
    //     navigationId: (this.location.getState() as any)?.navigationId + 1,
    //   });
    // }

    this.titleService.setTitle(title);
    ga('send', {
      hitType: 'pageview', location: prefix_url + slug.replace(/\/$/, ''), title, dimension3: categorySlug,
    });
    // dataLayer.push({
    //     'event': 'event', 'hitType': 'pageview', 'location': prefix_url + slug.replace(/\/$/, ''), 'title': title, 'dimension3': categorySlug
    // });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setStyle(window.innerWidth);
      this.detectedScreenSub = this.detectedScreenChangeService.windowResize.subscribe((a) => {
        this.setStyle(a.target.innerWidth);
      });
    }
    //this.mainPostLoaded = true;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.unsubscribeObservable();
    if (this.detectedScreenSub) {
      this.detectedScreenSub.unsubscribe();
    }
    // При закрытии статьи очищаем интервал, чтобы предотвратить утечки памяти
    // clearInterval(this.readingInterval);
  }

  private createObservable() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(([entry]) => {
        const val = 90; // proc
        const proc = (entry.intersectionRect.height / entry.rootBounds.height) * 100;
        this.isVisibleInView.next(proc >= val);
      }, {threshold: [0, 0.03, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.9, 1]});
      this.intersectionObserver.observe(this.firstPostRef.nativeElement);
    }
  }

  private unsubscribeObservable() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver && this.intersectionObserver.disconnect();
    }
  }

  private setStyle(elemWidth) {
    let scale = 1;
    if (elemWidth >= 768 && elemWidth < 1024) {
      // вычитаются падинги класса wrap
      scale = Math.ceil(((elemWidth - 32) / 992) * 10000) / 10000;
    }
    this.style = {
      transform: `scale(${scale})`, transformOrigin: 'left top', width: `calc(100% / ${scale})`,
    };
  }
}
